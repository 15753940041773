<template>
  <div class="container">
    <!-- 头部标题 -->
    <div>
      <span
        style="
          margin-left: 10px;
          width: 20px;
          height: 20px;
          padding: 5px;
          background: blue;
          display: inline-block;
          border-radius: 2px;
          text-align: center;
          line-height: 20px;
        "
      >
        <i class="el-icon-notebook-1"></i>
      </span>
      <span style="margin-left: 10px">
        <b>待办事项</b>
      </span>
    </div>
    <!-- 主体区 -->
    <div style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-card class="box-card" :body-style="{ padding: '20px 0px' }">
            <el-menu
              :default-active="0"
              class="el-menu-vertical-demo"
              text-color="black"
            >
              <el-menu-item
                :index="index"
                v-for="(i, index) in menu"
                :key="i"
                @click="clickHandler(index)"
              >
                <i :class="i.icon"></i>
                <span slot="title">{{ i.item }}</span>
              </el-menu-item>
            </el-menu>
          </el-card>
        </el-col>
        <!-- 右边菜单 -->
        <el-col :span="20">
          <el-card
            class="box-card"
            style="position: relative; width: 100%; height: 600px"
          >
            <template v-for="(item, index) in menu">
              <div
                :key="index"
                style="position: absolute"
                v-if="item.hidden == 'false'"
              >
                <div>
                  <i :class="item.icon"></i>
                  <span>{{ item.item }}</span>
                </div>
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in item.options"
                    :key="item"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div>
                  <el-table
                    :data="item.tableData"
                    style="width: 100%; height: 500px"
                  >
                    <el-table-column
                      prop="date"
                      label="日期"
                      width="180"
                    ></el-table-column>
                    <el-table-column
                      prop="name"
                      label="姓名"
                      width="180"
                    ></el-table-column>
                    <el-table-column
                      prop="address"
                      label="地址"
                      width="300"
                    ></el-table-column>
                  </el-table>
                </div>
              </div>
            </template>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
let pre = 0;
export default {
  data() {
    return {
      menu: [
        {
          item: '今日需联系线索',
          icon: 'el-icon-user-solid',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'false',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '今日需联系客户',
          icon: 'el-icon-star-on',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄22222',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 55555555551517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '今日需联系商机',
          icon: 'el-icon-s-help',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '分配给我的线索',
          icon: 'el-icon-s-cooperation',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '分配给我的客户',
          icon: 'el-icon-s-promotion',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '待审核合同',
          icon: 'el-icon-share',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '待审核回款',
          icon: 'el-icon-s-grid',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '待回款提醒',
          icon: 'el-icon-edit-outline',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '待回访合同',
          icon: 'el-icon-tickets',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
        {
          item: '待审核发票',
          icon: 'el-icon-paperclip',
          tableData: [
            {
              date: '2016-05-02',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1518 弄',
            },
            {
              date: '2016-05-04',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1517 弄',
            },
            {
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              date: '2016-05-03',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1516 弄',
            },
          ],
          hidden: 'true',
          options: [
            {
              value: '选项1',
              label: '黄金糕',
            },
            {
              value: '选项2',
              label: '双皮奶',
            },
            {
              value: '选项3',
              label: '蚵仔煎',
            },
            {
              value: '选项4',
              label: '龙须面',
            },
            {
              value: '选项5',
              label: '北京烤鸭',
            },
          ],
          value: '',
        },
      ],
    };
  },
  methods: {
    clickHandler(index) {
      if (pre != index) {
        console.log(pre, this.menu[pre]);
        this.menu[pre].hidden = 'true';
      }
      console.log(index, this.menu[index]);
      this.menu[index].hidden = 'false';
      pre = index;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
  padding-right: 0;
}
.el-menu {
  border-right: none;
}
</style>
